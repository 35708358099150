import * as yup from 'yup';

export const userEmail = yup.string().email();
export const usPhone = yup.string().matches(/^(\+1)?[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/);
export const cardNumber = yup.string().matches(/^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/);
export const cardNumberWithoutSpace = yup.string().matches(/^[0-9]{16}$/);
export const expirationDate = yup.string().length(5).matches(/^(0[1-9]|10|11|12)\/[0-9]{2}$/);
export const cvv = yup.string().matches(/^\d{3}$/, 'CVV is not valid');
export const zip = yup.string().matches(/^\d{5}(-\d{4})?$/);
export const firstName = yup.string().min(2).max(20);
export const lastName = yup.string().min(2).max(20);
export const ssn = yup.string().matches(/^(\d*-?)+\d*$/).max(21);
export const streetAddressMinLength = yup.string().min(2);
export const cityMinLength = yup.string().min(2);