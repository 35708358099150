import React, {useEffect, useState} from "react"

import {Box, Divider, Grid, Typography} from "@material-ui/core"
import {
    Text,
    MaskedText,
    Date,
    Checkbox,
    Select,
    Radio,
    DoctorSelect,
} from "../../../componets/fields"
import StepControls from '../../step-controls'

import schema from "./schema"
import useStyles from './styles'

import * as messages from "../../../constants/messages"
import * as rules from "../../../constants/rules"
import AutoComplete from "../../../componets/fields/Autocomplete";
import { useSelector } from "react-redux";
import { selectors as applicationSelectors } from "../../application/duck";
import { Alert } from "@material-ui/lab";

const GeneralForm = ({disabled, fields, application, onBack, step, onSubmit, name, id}) => {
    const pharmacyItems = useSelector(applicationSelectors.pharmacyItems);
    const fieldsArray = [...fields];
    const deliveryIndex = fieldsArray.findIndex(elem => elem.id === 'delivery');

    if (deliveryIndex !== -1 && !application.personalInfo.state.medicineDelivery) {
        fieldsArray.splice(deliveryIndex, 2);
    }

    const deliveryAddressIndex = fieldsArray.findIndex(elem => elem.id === 'deliveryAddress');
    const pharmacyIndex = fieldsArray.findIndex(elem => elem.id === 'pharmacy');
    const additionalPharmacy1Index = fieldsArray.findIndex(elem => elem.id === 'additionalPharmacy1');
    const additionalPharmacy2Index = fieldsArray.findIndex(elem => elem.id === 'additionalPharmacy2');
    const isPCPAvailableIndex = fieldsArray.findIndex(elem => elem.id === 'isPCPAvailable');

    if (!(deliveryIndex !== -1 && !application.personalInfo.state.medicineDelivery)) {
        [deliveryAddressIndex, pharmacyIndex, additionalPharmacy1Index, additionalPharmacy2Index].forEach(index => {
            if (index !== -1) {
                const field = fieldsArray[index];
                fieldsArray[index] = {
                    ...field,
                    required: 'false',
                    disabled: true
                };
            }
        });
    }

    const classes = useStyles();
    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fieldsState, setFieldsState] = useState(fieldsArray);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};

        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

        fieldsState.forEach((field, index) => {
            initialValues[field.id] = {value: field.initialValue || "", name: field.label, index: index};
            initialErrors[field.id] = "";
        });

        if (application) {
            if (application.data[id]) {
                let values = { ...initialValues };

                for (let key of Object.keys(application.data[id].values)) {
                    if (values[key]) {
                        values[key].value = application.data[id].values[key].value || '';
                    }
                }

                setForm({
                    name: application.data[id].name,
                    values: { ...values }
                });
            } else {
                setForm({
                    name: name, values: {
                        ...initialValues,
                        email: {value: application.personalInfo.email.value, name: "Email", index: application.personalInfo.email.index},
                        firstName: {value: application.personalInfo.firstName.value, name: "First name", index: application.personalInfo.firstName.index},
                        lastName: {value: application.personalInfo.lastName.value, name: "Last name", index: application.personalInfo.lastName.index},
                        phoneNumber: {value: application.personalInfo.phoneNumber.value, name: "Phone", index: application.personalInfo.phoneNumber.index},
                    }
                });
            }
        }

        setErrors(initialErrors);
    }, []);

    useEffect(() => {
        if (pharmacyIndex !== -1) {
            const pharmacyField = fieldsState[pharmacyIndex];
            const additionalPharmacy1Field = fieldsState[additionalPharmacy1Index];
            const additionalPharmacy2Field = fieldsState[additionalPharmacy2Index];
            pharmacyField.options = [];
            additionalPharmacy1Field.options = [];
            additionalPharmacy2Field.options = [];
            pharmacyItems.forEach(elem => {
                const pharmacyOption =
                    elem.StoreName + ', ' +
                    elem.City + ', ' +
                    elem.Address1 +
                    (elem.Address2 ? ', ' + elem.Address2 : '');
                pharmacyField.options.push(pharmacyOption);
                additionalPharmacy1Field.options.push(pharmacyOption);
                additionalPharmacy2Field.options.push(pharmacyOption);
            });
            fieldsState[pharmacyIndex] = {
              ...pharmacyField
            };
            fieldsState[additionalPharmacy1Index] = {
                ...additionalPharmacy1Field
            };
            fieldsState[additionalPharmacy2Index] = {
                ...additionalPharmacy2Field
            };
            setFieldsState([...fieldsState]);
        }

    }, [pharmacyItems]);

    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value?.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }

            for (const field of fieldsState) {
                const value = form.values[field.id].value;
                if (field.required === "true" &&
                    ((typeof value === 'string' && value.trim() === "") || value.length === 0)) {
                    err[field.id] = messages['required'];
                    noErrors = false;
                } else if (field.rules && !rules[field.rules].isValidSync(value) && !((field.required === 'false' || !field.required) && value === '')) {
                    noErrors = false;
                    err[field.id] = messages[field.rules];
                } else if (field.id === 'streetAddress' && !rules['streetAddressMinLength'].isValidSync(value)) {
                    noErrors = false;
                    err[field.id] = messages['streetAddressMinLength'];
                } else if (field.id === 'city' && !rules['cityMinLength'].isValidSync(value)) {
                    noErrors = false;
                    err[field.id] = messages['cityMinLength'];
                } else {
                    err[field.id] = "";
                }
            }

            setValid(noErrors);
        }

        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    const handleSubmit = () => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        if (valid) {
            onSubmit(form);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const handlePhoneFromBackend = (phone) => {
        return (String(phone).length > 10 && phone.startsWith('+1')) ? phone.substring(2) : phone;
    }

    const handleDeliveryChanges = (value, localForm) => {
        let deliveryFields = [deliveryAddressIndex];
        let selfPickupFields = [pharmacyIndex, additionalPharmacy1Index, additionalPharmacy2Index];
        if (value === 'Need delivery') {
            selfPickupFields.forEach(index => {
                const field = fieldsState[index];
                localForm = {...localForm, values: {...localForm.values, [field.id]: {...localForm.values[field.id], value: ''}}};
                fieldsState[index] = {
                    ...fieldsState[index],
                    required: 'false',
                    disabled: true
                };
            });
            deliveryFields.forEach(index => {
                fieldsState[index] = {
                    ...fieldsState[index],
                    required: 'true',
                    disabled: false
                };
            });
        } else {
            selfPickupFields.forEach(index => {
                fieldsState[index] = {
                    ...fieldsState[index],
                    required: [additionalPharmacy1Index, additionalPharmacy2Index].includes(index) ? 'false' : 'true',
                    disabled: false
                };
            });
            deliveryFields.map(index => {
                const field = fieldsState[index];
                localForm = {...localForm, values: {...localForm.values, [field.id]: {...localForm.values[field.id], value: ''}}};
                fieldsState[index] = {
                    ...field,
                    required: 'false',
                    disabled: true
                };
            });
        }
        setFieldsState([...fieldsState]);
        return localForm;
    }

    const handlePCPChanges = (value, localForm) => {

        const PCPFields = [...Array.from({ length: 5 }, (_, i) => isPCPAvailableIndex + i + 1)];

        if (value === 'Yes') {
            PCPFields.forEach(index => {
                const field = fieldsState[index];
                localForm = {...localForm, values: {...localForm.values, [field.id]: {...localForm.values[field.id], value: ''}}};
                fieldsState[index] = {
                    ...field,
                    required: fieldsArray[index].required,
                    disabled: false
                };
            });
        } else {
            PCPFields.forEach(index => {
                const field = fieldsState[index];
                localForm = {...localForm, values: {...localForm.values, [field.id]: {...localForm.values[field.id], value: ''}}};
                fieldsState[index] = {
                    ...fieldsState[index],
                    required: 'false',
                    disabled: true
                };
            });
        }

        setFieldsState([...fieldsState]);
        return localForm;
    }

    const handleChange = (key, value) => {
        let localForm = {...form, values: {...form.values, [key]: {...form.values[key], value: value}}};
        if (key === 'delivery') {
            localForm = handleDeliveryChanges(value, localForm);
        }

        if (key === 'isPCPAvailable') {
            localForm = handlePCPChanges(value, localForm);
        }
        setForm(localForm);
    };

    const getPharmacyWarning = () => {
        return (
            <Alert severity="info" style={{ marginBottom: '20px' }}>
                <span>Due to temporary restrictions beyond our control, we <b>don’t guarantee the receipt of medicines in<br/>
                    CVS, Rite Aid and Walmart pharmacies</b>. Please choose another pharmacy to get your medication.
                </span>
            </Alert>
        );
    }

    const getAdditionalPharmaciesWarning = () => {
        return (
            <Alert severity="info" style={{ marginBottom: '20px' }}>
                <span>We recommend selecting additional pharmacies. If your first pharmacy of choice doesn't take online prescriptions <br/>
                    or has shortage of particular medications, contact us, and we will immediately send your prescription to the next one you selected.
                </span>
            </Alert>
        );
    }

    const getWarning = (fieldId) => {
        if (fieldId === 'pharmacy') {
            return getPharmacyWarning();
        }

        return null;
    }

    return (
        <Box>
            {form && <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              disabled={disabled}
                              errorMessage={errors['firstName']}
                              id="firstName"
                              initialValue={form.values.firstName.value}
                              label={schema.firstName.label}
                              placeholder={schema.firstName.placeholder}
                              required={schema.firstName.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              disabled={disabled}
                              errorMessage={errors['lastName']}
                              id="lastName"
                              initialValue={form.values.lastName.value}
                              label={schema.lastName.label}
                              placeholder={schema.lastName.placeholder}
                              required={schema.lastName.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Text onChange={handleChange}
                              disabled={disabled}
                              errorMessage={errors['email']}
                              id="email"
                              initialValue={form.values.email.value}
                              label={schema.email.label}
                              placeholder={schema.email.placeholder}
                              required={schema.email.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <MaskedText onChange={handleChange}
                                    disabled={disabled}
                                    errorMessage={errors['phoneNumber']}
                                    mask={schema.phoneNumber.mask}
                                    id="phoneNumber"
                                    initialValue={handlePhoneFromBackend(form.values.phoneNumber.value)}
                                    label={schema.phoneNumber.label}
                                    adornment="+1"
                                    placeholder={schema.phoneNumber.placeholder}
                                    required={schema.phoneNumber.required}/>
                    </Grid>

                    {fieldsState.map((field, index) => (
                        !field.disabled && <Grid key={index} item xs={12}>
                            <Box>
                                {field.description &&
                                <Box>
                                    <br/>
                                    <Typography color="textSecondary" variant="body2">{field.description}</Typography>
                                </Box>}
                                {field.header &&
                                <Box>
                                    <Divider variant="fullWidth" className={classes.divider}/>
                                    <Typography gutterBottom variant="button">{field.header}</Typography>
                                </Box>}

                                {field.id === 'additionalPharmacy1' && getAdditionalPharmaciesWarning()}

                                {field.type === 'date' && <Date disabled={disabled}
                                      onChange={handleChange}
                                      required={field.required}
                                      errorMessage={errors[field.id]}
                                      initialValue={application.dateOfBirth || null}
                                      id={field.id}
                                />}

                                {field.type === 'masked-text' && <MaskedText onChange={handleChange}
                                                                             disabled={disabled}
                                                                             errorMessage={errors[field.id]}
                                                                             id={field.id}
                                                                             mask={field.mask}
                                                                             label={field.label}
                                                                             adornment={field.mask === 'usPhone' ? "+1" : ""}
                                                                             initialValue={handlePhoneFromBackend(form.values[field.id].value)}
                                                                             placeholder={field.placeholder}
                                                                             required={field.required}
                                />}

                                {field.type === 'text' && <Text onChange={handleChange}
                                                                disabled={disabled || field.disabled}
                                                                errorMessage={errors[field.id]}
                                                                id={field.id}
                                                                label={field.label}
                                                                initialValue={form.values[field.id]?.value}
                                                                placeholder={field.placeholder}
                                                                required={field.required}
                                />}
                                {field.type === 'select' && <Select onChange={handleChange}
                                                                    disabled={disabled}
                                                                    errorMessage={errors[field.id]}
                                                                    id={field.id}
                                                                    label={field.label}
                                                                    options={field.options}
                                                                    initialValue={form.values[field.id].value}
                                                                    required={field.required}
                                />}
                                {field.type === 'radio' && <Radio onChange={handleChange}
                                                                  disabled={disabled}
                                                                  errorMessage={errors[field.id]}
                                                                  required={field.required}
                                                                  id={field.id}
                                                                  label={field.label}
                                                                  direction={'column'}
                                                                  options={field.options}
                                                                  warning={getWarning(field.id)}
                                                                  initialValue={form.values[field.id].value}
                                />}
                                {field.type === 'checkbox' && <Checkbox cols={2} onChange={handleChange}
                                                                        disabled={disabled}
                                                                        errorMessage={errors[field.id]}
                                                                        id={field.id}
                                                                        label={field.label}
                                                                        options={field.options}
                                                                        initialValue={form.values[field.id].value}
                                                                        required={field.required}
                                />}
                                {field.type === 'doctor-select' && <DoctorSelect onChange={handleChange}
                                                                                 disabled={disabled}
                                                                                 errorMessage={errors[field.id]}
                                                                                 id={field.id}
                                                                                 label={field.label}
                                                                                 initialValue={form.values[field.id].value}
                                                                                 required={field.required}
                                />}
                                {field.type === 'autocomplete' && <AutoComplete onChange={handleChange}
                                                                                disabled={disabled || field.disabled}
                                                                                errorMessage={errors[field.id]}
                                                                                id={field.id}
                                                                                setCustomValue={field.setCustomValue}
                                                                                options={field.options}
                                                                                placeholder={field.placeholder}
                                                                                label={field.label}
                                                                                initialValue={form.values[field.id].value}
                                                                                required={field.required}
                                                                                warning={getWarning(field.id)}
                                />}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </form>}

            <StepControls step={step} disabled={disabled || uploading} onBack={onBack} onNext={handleSubmit}/>
        </Box>
    );
}

export default GeneralForm;
